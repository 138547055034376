import { SxProps, Theme } from '@mui/material';
import { resolveTypographyVariantStyles } from '@aspendental/themes';

import { Sizes } from '@/types';
import { Maybe } from '@/types/generated';

import { IImageAsset } from '../ImageAsset/ImageAsset';

export const wrapper = {
	display: 'block',
	margin: '0 auto',
	'& [data-test-id="group_multicards_cards"]': {
		maxWidth: 'unset',
	},
};

export const backgroundImageParsed = (
	{ desktopImage, mobileImage }: IImageAsset,
	fixBackgroundToBottom?: Maybe<boolean>,
	isSmallScreen?: Maybe<boolean>
): object => {
	if (!desktopImage && !mobileImage) return {};

	const backgroundPosition = fixBackgroundToBottom ? 'bottom center' : 'center';
	const backgroundSize = fixBackgroundToBottom ? 'auto' : 'cover';
	const displayImage = isSmallScreen && mobileImage?.image ? mobileImage : desktopImage;
	const imageUrl = displayImage?.image?.cdn_url || '';

	return {
		backgroundPosition,
		backgroundSize,
		backgroundImage: `url(${imageUrl})`,
		backgroundRepeat: 'no-repeat',
	};
};

export const backgroundContainerStyles = (
	backgroundImage?: Maybe<IImageAsset>,
	fixBackgroundToBottom?: Maybe<boolean>,
	isSmallScreen?: Maybe<boolean>
): object => {
	return {
		paddingY: '3.75rem',
		...(backgroundImage && backgroundImageParsed(backgroundImage, fixBackgroundToBottom, isSmallScreen)),
	};
};

export const getHeaderSpacingValue = (headerSpacing: Maybe<Sizes> = 'Medium') => {
	const headerSpacingValues = {
		Small: 1.5,
		Medium: 2.5,
		Large: 4,
		XLarge: 6,
	} as { [key: string]: number };

	if (!headerSpacing) {
		return headerSpacingValues['Medium'];
	}

	return headerSpacingValues[headerSpacing];
};

export const getSectionWrapperStyles = (
	sectionAlignment?: Maybe<string>,
	headerSpacing: Maybe<Sizes> = 'Medium'
): SxProps => {
	const headerSpacingValue = getHeaderSpacingValue(headerSpacing);

	return {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: sectionAlignment ?? 'flex-start',
		alignItems: sectionAlignment ?? 'flex-start',
		textAlign: sectionAlignment === 'center' ? 'center' : 'left',
		marginBottom: `${headerSpacingValue}rem`,
		gap: '0.75rem',
		marginLeft: sectionAlignment === 'flex-start' ? 0 : 'auto',
	};
};

export const getCardsWrapperStyles = ({
	isDarkBackground,
	cardsAlignment,
	numberOfCards,
	shouldDisplayCarouselOnMobile,
	isSmallScreen,
	displayAsStepper,
	shouldDisplayCarouselOnDesktop,
}: {
	isDarkBackground: boolean;
	isSmallScreen: boolean;
	cardsAlignment?: Maybe<string>;
	numberOfCards: number;
	shouldDisplayCarouselOnMobile: boolean;
	displayAsStepper: boolean;
	shouldDisplayCarouselOnDesktop?: Maybe<boolean>;
}) => ({
	display: (isSmallScreen && shouldDisplayCarouselOnMobile) || shouldDisplayCarouselOnDesktop ? 'flex' : 'grid',
	gridTemplateColumns: { xs: 'repeat(1, 1fr)', md: `repeat(${numberOfCards}, ${numberOfCards}fr)` },
	gridColumnGap: displayAsStepper ? 0 : '.75rem',
	gridRowGap: displayAsStepper ? 0 : '1rem',
	alignItems: 'stretch',
	alignContent: 'center',
	justifyContent: 'center',
	gap: displayAsStepper ? 0 : '0.75rem',
	flexWrap: 'wrap',
	boxSizing: 'border-box',
	padding: '0',
	textAlign: isSmallScreen && displayAsStepper ? 'left' : cardsAlignment || 'left',
	...(isDarkBackground
		? {
				'& .MuiTypography-root': {
					color: 'text.light',
				},
		  }
		: {}),
});

export const getHeadingStyles = (theme: Theme) => ({
	...resolveTypographyVariantStyles(theme, 'header1'),
});

export const richTextDescStyles = (isDarkBackground: boolean) => ({
	'& .MuiLink-root': {
		color: isDarkBackground ? 'text.light' : 'text.interactive',
	},
});

export const getMultiCardButtonsStyles = (isSmallScreen: boolean) => ({
	display: 'flex',
	alignContent: 'center',
	justifyContent: 'center',
	gap: '1rem',
	mt: isSmallScreen ? 0 : '2.5rem',
	flexWrap: 'wrap',
});

export const multiCardChipStyles = {
	color: 'secondary.main',
	borderColor: 'secondary.main',
	'&:hover': {
		color: 'secondary.main',
		backgroundColor: 'neutrals.white',
		opacity: 0.8,
	},
	'&.Mui-selected': {
		backgroundColor: 'secondary.main',
		color: 'neutrals.white',
		'&:hover': {
			color: 'neutrals.white',
			backgroundColor: 'secondary.main',
			opacity: 0.8,
		},
	},
};
