import { Theme } from '@mui/material';

import { Maybe, TMarqueeHeight } from '@/types';

export const sliderWrapStyles = {
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
};

const getPaddingDependOnHeight = (height: TMarqueeHeight) => {
	switch (height) {
		case 'Small':
			return '0.5rem';
		case 'Medium':
			return '0.875rem';
		case 'Large':
			return '1.25rem';
		default:
			return '0.5rem';
	}
};

export const sliderStyles = {
	width: '100%',
	overflow: 'hidden',
	whiteSpace: 'nowrap',
	display: 'flex',
	alignItems: 'center',
};

export const marqueeIconWrapper = {
	height: '1.5rem',
	paddingRight: '1rem',
	display: 'inline-block',
	lineHeight: '1rem',
};

export const marqueeDot = (isDark: boolean, theme: Theme) => {
	const backgroundColor = isDark ? theme.palette?.text.light : theme.palette?.text.primary;

	return {
		display: 'inline-block',
		height: '0.5rem',
		width: '0.5rem',
		backgroundColor,
		borderRadius: '50%',
	};
};

export const getSlideTrackStyles = (params?: {
	withAutoScrolling?: Maybe<boolean>;
	height?: Maybe<TMarqueeHeight>;
}) => ({
	'@keyframes marquee': {
		'0%': {
			transform: 'translateX(100%)',
		},
		'100%': {
			transform: 'translateX(-100%)',
		},
	},
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	whiteSpace: 'nowrap',
	justifyContent: 'center',
	width: '100%',
	flexWrap: params?.withAutoScrolling ? 'nowrap' : 'wrap',
	padding: `${getPaddingDependOnHeight(params?.height ?? 'Small')} 0`,
	...(params?.withAutoScrolling && { animation: 'marquee 60s linear infinite' }),
});

export const slideStyles = {
	whiteSpace: 'nowrap',
	padding: '0 0.5rem',
	display: 'inline-flex',
	alignItems: 'center',
};
