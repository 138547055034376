import { Fragment, useEffect, useState } from 'react';

import { Box, Typography, useTheme } from '@mui/material';

import { ContentfulBackground, Maybe, TMarqueeHeight } from '@/types';
import { Marquee as IMarquee } from '@/types/generated';
import { extendArrayToLength, resolveContentfulBgColor, textToJumpLink } from '@/utils';

import TemplateContainer from '../TemplateContainer/TemplateContainer';
import TAGSvgIcon, { IconName } from '../TAGSvgIcon';

import {
	getSlideTrackStyles,
	sliderStyles,
	slideStyles,
	sliderWrapStyles,
	marqueeDot,
	marqueeIconWrapper,
} from './Marquee.styles';
import { EXTENDED_TEXT_LIST_SIZE } from './Marquee.helpers';

export interface IMarqueeProps extends IMarquee {
	/**
	 * Background color for the section, and it will also override the background color of the child components.
	 * @type { Maybe<ContentfulBackground>}
	 * @memberof IMarquee
	 * @default 'White'
	 *
	 **/
	backgroundColor?: Maybe<ContentfulBackground>;
	/**
	 * Test scrolling list (10 items)
	 * @type {Maybe<Maybe<string>[]>}
	 * @required
	 * @memberof IMarquee
	 * @default []
	 **/
	textList?: Maybe<Maybe<string>[]>;
	/**
	 * Icon between text
	 * @type {Maybe<IconName>}
	 * @memberof IMarquee
	 */
	icon?: IconName;
	/**
	 * Turn on / off auto-scrolling
	 * @type {Maybe<boolean>}
	 * @memberof IMarquee
	 * @default true
	 **/
	withAutoScrolling?: Maybe<boolean>;
	/**
	 * Change height of the marquee box
	 * @type {Maybe<TMarqueeHeight>}
	 * @memberof IMarquee
	 * @default Small
	 **/
	height?: Maybe<TMarqueeHeight>;
	/**
	 * Used to identify a component on a page for the purpose of jumping to it using the anchor link.
	 *
	 * @type {Maybe<string>}
	 * @memberof IMarquee
	 */
	anchorId?: Maybe<string>;
}

export default function Marquee({
	textList,
	backgroundColor = 'White',
	icon,
	withAutoScrolling = true,
	height = 'Small',
	anchorId,
}: Readonly<IMarqueeProps>) {
	const [isClient, setIsClient] = useState(false);

	useEffect(() => {
		setIsClient(true);
	}, []);

	const theme = useTheme();
	const isDark = backgroundColor?.toLowerCase().includes('dark');
	const bgColor = resolveContentfulBgColor(backgroundColor as ContentfulBackground, theme);
	const updatedTextList = withAutoScrolling
		? extendArrayToLength(textList ?? [], EXTENDED_TEXT_LIST_SIZE)
		: textList ?? [];
	const textColor = isDark ? 'text.light' : 'primary.dark';

	return (
		<TemplateContainer
			maxWidth={false}
			contentfulBackgroundColor={bgColor}
			{...(anchorId && { id: textToJumpLink(anchorId) })}
		>
			<Box data-test-id="section_marquee" sx={sliderWrapStyles}>
				<Box sx={sliderStyles}>
					{isClient && (
						<Box sx={getSlideTrackStyles({ withAutoScrolling, height })} data-test-id="marquee_slide_track">
							{updatedTextList.map((textItem, index) =>
								textItem ? (
									<Fragment key={`${textItem}-${index}`}>
										<Typography
											color={textColor}
											variant="header4"
											sx={slideStyles}
											data-test-id="marquee_text"
										>
											{index !== 0 && (
												<span style={marqueeIconWrapper}>
													{icon ? (
														<TAGSvgIcon
															htmlColor={isDark ? 'neutrals.white' : 'tertiary.main'}
															icon={icon}
															size={24}
															data-test-id="marquee_icon"
														/>
													) : (
														<span
															style={{
																...marqueeDot(!!isDark, theme),
															}}
														/>
													)}
												</span>
											)}
											{textItem}
										</Typography>
									</Fragment>
								) : null
							)}
						</Box>
					)}
				</Box>
			</Box>
		</TemplateContainer>
	);
}
